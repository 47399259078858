import React, { useEffect } from 'react'
import '../styles/Tutor.css'
import { useState } from 'react'

function Tutor2(props) {

    var setScreen = props.ScreenImg;
    var showText = props.ShowText;
    var theTheme = props.Theme;

    const [TutorAndroid, setTutorAndroid] = useState('TutorPhoneAndroid gone');
    const [SingDroid, setSingDroid] = useState('SingDroid gone');
    const [ScreenText, setScreenText] = useState('');
    const [zeigeSprechblase, setSprechblase] = useState('SprechblaseWrapper gone');
    const [WorldTheme, setWorldTheme] = useState('Default');
    const [TutorWrapper, setTutorWrapper] = useState('TutorWrapperDefault');
    const [TutorPhoneFrame, setTutorPhoneFrame] = useState('TutorPhoneFrameDefault');
    const [TutorPhoneScreen, setTutorPhoneScreen] = useState('TutorPhoneScreenDefault');
    const [TutorPhoneHomeButton, setTutorPhoneHomeButton] = useState('TutorPhoneHomeButtonDefault');
    const [Rotate1, setRotate1] = useState('Rotate1Default');
    const [Rotate2, setRotate2] = useState('Rotate2Default');

    const TutorialTexte = [
        "Hey! Ich möchte gern ein kleines Lese- und Geschwindigkeitsspiel mit dir spielen. Bist du bereit?", 
        "Also, pass auf, das Spiel geht so:",
        "Ich zeige dir ein Wort. Zum Beispiel das hier:",
        "Als erstes versuche das Wort zu lesen.",
        "Wenn du es gelesen hast, dann tippe auf die WEITER-Taste!",
        "Prima! Und jetzt zeige ich dir noch ein Wort. Zum Beispiel das hier:",
        "Und jetzt Achtung: Wenn du denkst, dass das jetzt wieder dasselbe, gesuchte Wort ist, dann tippst du auf diese Tasten mit dem Häkchen darauf.",
        "Wenn du aber denkst, dass das jetzt ein anderes und nicht das gesuchte Wort ist, dann tippst du auf diese Taste mit dem Kreuz darauf.",
        "Gut. Und was ist mit diesem Wort?",
        "Denkst du, dass das wieder das gesuchte Wort ist? Oder ist das ein anderes Wort? Tippe auf die passende Taste!",
        "Okay! Und was ist mit diesem Wort? Ist das das gesuchte Wort oder ein anderes? Entscheide dich!",
        "Hm, ziemlich gut.",
        "Jetzt gibt es ein neues gesuchtes Wort.", 
        "Nämlich das hier. Versuch es zu lesen, dann tippe auf die WEITER-Taste!",
        "So und dieses Wort? Ist das jetzt wieder das gesuchte Wort oder ein anderes. Tippe auf die richtige Taste!",
        "Und das Wort hier? Ist das das gesuchte Wort aus der Lupe? Entscheide!",
        "Und das Wort hier?",
        "Richtig gut gemacht! Jetzt kommen noch viele weitere Wörter. Versuch immer, so schnell wie möglich zu antworten.",
        "Viel Glück!",
        "Das neue gesuchte Wort ist:",
        "Du hast es geschafft und bist fertig!",
        "Vielen Dank, dass du an meinem kleinen Quiz teilgenommen hast!",
        "Dann tschüss und bis bald!",
        "Sehr gut gemacht! Jetzt machen wir was anderes.",
        "",
        "Zum Beispiel das Wort...",
        "Wenn du das Wort verstanden hast, dann tippe auf die WEITER-Taste",
        "Wenn du das Wort noch nicht verstanden hast, dann kannst du gern noch einmal auf den LAUTSPRECHER tippen",
        "Sehr gut, jetzt muss wieder gelesen werden.",
        "Ist das hier das Wort, das ich eben gesagt habe?",
        "Wenn du denkst JA, dann tippe auf die grüne Taste mit dem Häkchen!",
        "Wenn du denkst NEIN, dann tippe auf die rote Taste mit dem Kreuz!",
        "Okay! Und das Wort hier? Habe ich das eben gesagt oder etwas anderes? Tippe auf die richtige Taste!",
        "Und das Wort hier? Habe ich das gesagt oder was anderes? Entscheide dich!",
        "Ja. Du hast es verstanden!",
        "So, jetzt sage ich dir noch viele weitere Wörter.",
        "Wenn du ein Wort nicht verstanden hast, kannst du immer auf den Lautsprecher tippen, dann sage ich dir das Wort nochmal",
        "Denk dran, immer so schnell wie möglich zu antworten.",
        "Also, viieeel Glück!",
        "Hm... hm...",
        "Manchmal da ist das gesuchte Wort auf einem Bild zu sehen.",
        "Was zum Beispiel siehtst du hier?",
        "Wenn du weißt, was auf dem Bild zu sehen ist, dann tippe auf die WEITER-Taste!",
        "Gut. Jetzt muss dann wieder gelesen werden!",
        "Ist das hier das Wort, das eben auf dem Bild zu sehen war?",
        "Wenn du denkst: Das stimmt! Dann tippe auf die GRÜNE Taste mit dem Häkchen.",
        "Wenn du denkst, dass das ein anderes Wort ist, dann tippe auf die ROTE Taste mit dem Kreuz.",
        "Super! Und das Wort hier?",
        "Ist das das Wort von dem Bild?",
        "Und das Wort hier? Stimmt das? Entscheide dich!",
        "Ich denke, du hast auch diese Aufgabe verstanden!",
        "Jetzt kommen wieder viele weitere Wörter.",
        "Versuch nochmal, so schnell es geht zu antworten!",
        "Also. Viiieeel Glück!!!",
        "Perfekt! Mission Nummer 2: Jetzt zeige ich dir einen Satz. Zum Beispiel diesen hier:",
        "Dazu zeige ich dir drei Bilder. Ein erstes, ein zweites und ein drittes.",
        "Nun entscheide, welches Bild am besten zum dem Satz passt und klicke darauf.",
        "Ja, genau so. Jetzt kommen noch viele weitere Sätze. Versuche nochmal, so schnell wie möglich zu antworten.",
        "Viel Glück!",
        "Super, du bist fast fertig! Jetzt möchte ich noch ein bisschen was von dir wissen:",
        "Deshalb die erste Frage: Welche Sprache sprichst du zu Hause?",
        "Okay. Welche Sprache sprichst du denn zu Hause?",
        "Super. Nächste Frage: Wie viele Bücher oder E-Books gibt es bei dir zu Hause?",
        "Perfekt! Und meine letzte Frage: Wie oft liest du zu Hause etwas?",
        "Okay! Und noch eine Frage: Bist du ein Mädchen oder ein Junge?"
        
    ]

    useEffect(()=> {
        if(theTheme == "SecretDucks"){
            setWorldTheme('SecretDucks');  
            setTutorWrapper('TutorWrapperSD');  
            setTutorPhoneFrame('TutorPhoneFrameSD'); 
            setTutorPhoneHomeButton('TutorPhoneHomeButtonSD')   
            setRotate1('Rotate1SD');
            setRotate2('Rotate2SD');     
        }
        if(theTheme == "Default"){
            setWorldTheme('Default');
            setTutorWrapper('TutorWrapperSD');  
            setTutorPhoneFrame('TutorPhoneFrameDefault'); 
            setTutorPhoneHomeButton('TutorPhoneHomeButtonDefault');
            setRotate1('Rotate1Default'); 
            setRotate2('Rotate2Default');    
        }
    })

    useEffect(() => {
        if(setScreen=="Android"){
            if(theTheme=="Default")
            {setTutorPhoneScreen('TutorPhoneScreenDefaultWait')}
            if(theTheme=="SecretDucks")
            {setTutorAndroid('TutorPhoneAndroid'); 
            setSingDroid('SingDroid gone');
            setTutorPhoneScreen('TutorPhoneScreenSD');}}
        if(setScreen=="Neutral"){
            if(theTheme=="SecretDucks")
            {setTutorAndroid('TutorPhoneAndroid gone');setSingDroid('SingDroidN');}
            if(theTheme=="Default"){setTutorPhoneScreen('TutorPhoneScreenDefaultN')}}
        if(setScreen=="Point"){
            if(theTheme=="SecretDucks"){setTutorAndroid('TutorPhoneAndroid gone');setSingDroid('SingDroidPoint');}
            if(theTheme=="Default"){setTutorPhoneScreen('TutorPhoneScreenDefaultP');}
        }
        if(setScreen=="Think"){if(theTheme=="SecretDucks"){setTutorAndroid('TutorPhoneAndroid gone');setSingDroid('SingDroidThink');}}
        if(setScreen=="Doubt"){if(theTheme=="SecretDucks"){setTutorAndroid('TutorPhoneAndroid gone');setSingDroid('SingDroidDoubt');}}
        if(setScreen=="StopIt"){if(theTheme=="SecretDucks"){setTutorAndroid('TutorPhoneAndroid gone');setSingDroid('SingDroidStopIt');}}
        if(setScreen=="Loben")
        {if(theTheme=="SecretDucks"){setTutorAndroid('TutorPhoneAndroid gone');setSingDroid('SingDroidLob');}
        if(theTheme=="Default"){setTutorPhoneScreen('TutorPhoneScreenDefaultLob')};
        }
        if(setScreen=="Love"){if(theTheme=="SecretDucks"){setTutorAndroid('TutorPhoneAndroid gone');setSingDroid('SingDroidLove');}}
        if(setScreen=="Talk")
        {if(theTheme=="SecretDucks")
        {setTutorAndroid('TutorPhoneAndroid gone');setSingDroid('SingDroidTalk');}
        if(theTheme=="Default"){setTutorPhoneScreen('TutorPhoneScreenDefaultT')}}
        if(setScreen=="Oh"){if(theTheme=="SecretDucks"){setTutorAndroid('TutorPhoneAndroid gone');setSingDroid('SingDroidOh');}}

    })

    useEffect(()=>{
        if(theTheme=="SecretDucks"){
        if(showText == "0"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper gone')}, 1000);
        }
        if(showText == "1"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[0])}, 2000);
        }
        if(showText == "2"){setScreenText(TutorialTexte[1]);}
        if(showText == "3"){setScreenText(TutorialTexte[2]);}
        if(showText == "4"){setScreenText(TutorialTexte[3]);}
        if(showText == "5"){setScreenText(TutorialTexte[4]);}
        if(showText == "5b"){setScreenText(TutorialTexte[5]);}
        if(showText == "6"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[5])}, 2000);
        }
        if(showText == "7"){setScreenText(TutorialTexte[6]);}
        if(showText == "8"){setScreenText(TutorialTexte[7]);}
        if(showText == "8b"){setScreenText(TutorialTexte[8]);}
        if(showText == "9"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[8])}, 2000);
        }
        if(showText == "10"){setScreenText(TutorialTexte[9]);}
        if(showText == "10b"){setScreenText(TutorialTexte[10]);}
        if(showText == "11"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[10])}, 2000);
        }
        if(showText == "11b"){setScreenText(TutorialTexte[11]);}
        if(showText == "12"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[11])}, 2000);
        }
        if(showText == "13"){setScreenText(TutorialTexte[12]);}
        if(showText == "14"){setScreenText(TutorialTexte[13]);}
        if(showText == "14b"){setScreenText(TutorialTexte[14]);}
        if(showText == "15"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[14])}, 2000);
        }
        if(showText == "15b"){setScreenText(TutorialTexte[15]);}
        if(showText == "16"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[15])}, 2000);
        }
        if(showText == "16b"){setScreenText(TutorialTexte[16]);}
        if(showText == "17"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[16])}, 2000);
        }
        if(showText == "17b"){setScreenText(TutorialTexte[17]);}
        if(showText == "18"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[17])}, 2000);
        }
        if(showText == "19"){setScreenText(TutorialTexte[18]);}
        if(showText == "19b"){setScreenText(TutorialTexte[19]);}
        if(showText == "20"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[19])}, 2000);
        }
        if(showText == "21"){setScreenText(TutorialTexte[20]);}
        if(showText == "21b"){setScreenText(TutorialTexte[21]);}
        if(showText == "22"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[21])}, 2000);
        }
        if(showText == "22b"){setScreenText(TutorialTexte[22]);}
        if(showText == "23"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[17])}, 2000);
        }
        if(showText == "24"){setScreenText(TutorialTexte[18]);}
        if(showText == "24b"){setScreenText(TutorialTexte[24]);}
        if(showText == "25"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[23])}, 2000);
        }
        if(showText == "25b"){setScreenText(TutorialTexte[24]);}
        if(showText == "25c"){setScreenText(TutorialTexte[25]);}
        if(showText == "25d"){setScreenText(TutorialTexte[26]);}
        if(showText == "25e"){setScreenText(TutorialTexte[27]);}
        if(showText == "25f"){setSprechblase('SprechblaseWrapper gone')}
        if(showText == "25g"){setScreenText("");}
        if(showText == "26"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
            setTimeout(() => {setScreenText(TutorialTexte[28])}, 2000);
        }
        if(showText == "26b"){setScreenText(TutorialTexte[29]);}
        if(showText == "26c"){setScreenText(TutorialTexte[30]);}
        if(showText == "26d"){setScreenText(TutorialTexte[31]);}
        if(showText == "26e"){setScreenText("");setSprechblase('SprechblaseWrapper gone')}

        if(showText == "27"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[32])}, 200);
        }
        if(showText == "27b"){setScreenText("");setSprechblase('SprechblaseWrapper gone')}
        if(showText == "28"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[33])}, 200);
        }

        if(showText == "29"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[34])}, 200);
        }
        if(showText == "29b"){setScreenText(TutorialTexte[35]);}
        if(showText == "29c"){setScreenText(TutorialTexte[36]);}
        if(showText == "29d"){setScreenText(TutorialTexte[37]);}
        if(showText == "29e"){setScreenText(TutorialTexte[38]);}
        
        
        if(showText == "30"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[39])}, 200);
        }
        if(showText == "30b"){setScreenText(TutorialTexte[40]);}
        if(showText == "30c"){setScreenText(TutorialTexte[41]);}
        if(showText == "30d"){setScreenText(TutorialTexte[42]);}

        if(showText == "31"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[43])}, 200);
        }
        if(showText == "31b"){setScreenText(TutorialTexte[44]);}
        if(showText == "31c"){setScreenText(TutorialTexte[45]);}
        if(showText == "31d"){setScreenText(TutorialTexte[46]);}

        if(showText == "32"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[47])}, 200);
        }
        if(showText == "32b"){setScreenText(TutorialTexte[48]);}

        if(showText == "33"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[49])}, 200);
        }

        if(showText == "34"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[50])}, 200);
        }
        if(showText == "34b"){setScreenText(TutorialTexte[51]);}
        if(showText == "34c"){setScreenText(TutorialTexte[52]);}
        if(showText == "34d"){setScreenText(TutorialTexte[53]);}


        if(showText == "35"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[54])}, 200);
        }
        if(showText == "35b"){setScreenText(TutorialTexte[55]);}
        if(showText == "35c"){setScreenText(TutorialTexte[56]);}

        if(showText == "36"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[57])}, 200);
        }

        if(showText == "36b"){setScreenText(TutorialTexte[58]);}

        if(showText == "40"){setScreenText(TutorialTexte[39]);}
        if(showText == "41"){setScreenText(TutorialTexte[40]);}

        if(showText == "80"){setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 1000);
        setTimeout(() => {setScreenText(TutorialTexte[42])}, 2000);}
        if(showText == "81"){setScreenText(TutorialTexte[43]);}
        if(showText == "82"){setScreenText(TutorialTexte[44]);}
        if(showText == "83"){setScreenText(TutorialTexte[45]);}

        if(showText == "100"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[59])}, 200);
        }
        if(showText == "100b"){setScreenText(TutorialTexte[60]);}
        if(showText == "101"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[61])}, 200);
        }
        if(showText == "102"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[62])}, 200);
        }
        if(showText == "103"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[63])}, 200);
        }

        if(showText == "104"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[64])}, 200);
        }

        if(showText == "105"){
            setTimeout(() => {setSprechblase('SprechblaseWrapper')}, 100);
            setTimeout(() => {setScreenText(TutorialTexte[20])}, 200);
        }
        if(showText == "105b"){setScreenText(TutorialTexte[21]);}
        if(showText == "105c"){setScreenText(TutorialTexte[22]);}
    }
    })

  return (
    <div className={TutorWrapper}>
        <div className={TutorPhoneFrame}>
            <div className={TutorPhoneScreen}>
                <div className={SingDroid}></div>
                <div className={TutorAndroid}>
                <div className='TutorPhone_Time'>10:10</div>
                    <div className='TutorPhone_google'>G</div>
                    <div className='TutorPhone_Apps'>
                        <div className='TutorPhoneA1'></div>
                        <div className='TutorPhoneA2'></div>
                        <div className='TutorPhoneA3'></div>
                        <div className='TutorPhoneA4'></div>
                    </div>
                </div>

            </div>
            
            <div className={TutorPhoneHomeButton}></div>
            <div className={Rotate1}></div>
            <div className={Rotate2}></div>
            

        </div>
        <div className={zeigeSprechblase}>
            <div className='SprechblaseBlase'>{ScreenText}</div>
            <div className='SprechblaseNase'></div>
        </div>
    </div>
  )
}

export default Tutor2