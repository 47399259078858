import React, { useEffect, useState } from 'react'
import '../styles/World.css';


function World(props) {

    var aktuelleSzene = props.Szene;
    var theTheme = props.Theme;
    
    const [Tape1,setTape1] = useState('Element gone');
    const [Tape2,setTape2] = useState('Element gone');
    const [Tape3,setTape3] = useState('Element gone');
    const [Tape4,setTape4] = useState('Element gone');
    const [Tape5,setTape5] = useState('Element gone right');
    const [Tape6,setTape6] = useState('Element gone right');
    const [DekoSmartphone, setDekoSmartphone] = useState('Element gone left');
    const [DekoLupe, setDekoLupe] = useState('Element gone left');
    const [duckPic1, setDuckPic1] = useState('Element gone right');
    const [duckPic2, setDuckPic2] = useState('Element gone right');
    const [DatenPapier, setDatenPapier] = useState('DatenPapier');
    const [WorldTheme, setWorldTheme] = useState('Default');
    const [tischplatte, setTischplatte] = useState('TischplatteSD');
    const [DSMPFrame, setDSMPFrame] = useState('DSMP_FrameDefault')
    const [DSMPScreen, setDSMPScreen] = useState('DSMPScreenDefault');
    const [DSMPHB, setDSMPHB] = useState('DSMPHomeButtonDefault');
    const [Rotate1, setRotate1] = useState('Rotate1DefaultDeko');
    const [Rotate2, setRotate2] = useState('Rotate2DefaultDeko');
    const [EckeLO, setEckeLO] = useState('EckeLO');
    const [EckeRO, setEckeRO] = useState('EckeRO');
    const [EckeLU, setEckeLU] = useState('EckeLU');
    const [EckeRU, setEckeRU] = useState('EckeRU');
    const [KussiPic, setKussiPic] = useState('KussiPicDefault');
    const [DuckPicFrame, setDuckPicFrame] = useState('DuckPicFrameDefault');
    const [TestSpeaker, setTestSpeaker] = useState('TestSpeaker_gone');
    const [audioPlaying, setAudioPlaying] = useState(false);

   

    

    useEffect(()=> {
        if(theTheme == "SecretDucks"){
            setWorldTheme('SecretDucks');
            setTischplatte('TischplatteSD');
        }
        if(theTheme == "Default"){
            setWorldTheme('Default');
            setTischplatte('TischplatteDefault');
        }
    })

    useEffect(() => {
        if(WorldTheme=="SecretDucks"){
            setEckeLO('Ecke gone');  
            setEckeRO('Ecke gone');
            setEckeLU('Ecke gone');
            setEckeRU('Ecke gone');  
            setKussiPic('KussiPic');
            setDuckPicFrame('DuckPicFrameSD');
        if(aktuelleSzene == 'startschreibtisch')
        {
            setTape1('Tape1');
            setTape2('Tape2');
            setTape3('Tape3');
            setTape4('Tape4');
            setTape5('Tape5');
            setTape6('Tape6');
            setDekoSmartphone('DekoSmartPhoneWrapper');
            setDSMPScreen('DSMPScreenSD');
            setDSMPFrame('DSMP_FrameSD');
            setDekoLupe('DekoLupeWrapper');
            setDSMPHB('DSMPHomeButtonSD');
            setDuckPic1("duckpic1");
            setDuckPic2("duckpic2");
            setRotate1('Rotate1SD');
            setRotate2('Rotate2SD'); 
            setDatenPapier('DatenPapierSD');
            setTestSpeaker('TestSpeaker_View');          
        }

        if(aktuelleSzene == 'diagnoseszene')
        {
            setTape1('Element gone');
            setTape2('Element gone');
            setTape3('Element gone');
            setTape4('Element gone');
            setDekoSmartphone('Element gone left');
            setDekoLupe('Element gone left');
            setTape5('Element gone right');
            setTape6('Element gone right');
            setDuckPic1('Element gone right');
            setDuckPic2('Element gone right');
            setDatenPapier('Element gone');
            setTestSpeaker('TestSpeaker_gone');  
        }
        }
        if(WorldTheme=="Default"){
            setTape1('Element gone');
            setTape2('Element gone');
            setTape3('Element gone');
            setTape4('Element gone');
            setTape5('Element gone right');
            setTape6('Element gone right');
            setDekoLupe('Element gone left');
            setRotate1('Rotate1DefaultDeko');
            setRotate2('Rotate2DefaultDeko');
            setDuckPic2('Element gone right');  

            if(aktuelleSzene == 'startschreibtisch')
            {
            setDSMPFrame('DSMP_FrameDefault');
            setDSMPScreen('DSMPScreenDefault');
            setDSMPHB('DSMPHomeButtonDefault');
            setDuckPic1("duckpic1Default");
            setDatenPapier('DatenPapierDefault');
            setTestSpeaker('TestSpeakerDefault_View');  
            setTape1('Element gone');
            setTape2('Element gone');
            setTape3('Element gone');
            setTape4('Element gone');
            setDekoSmartphone('Element gone2');
            setDekoLupe('Element gone left');
            setTape5('Element gone right');
            setTape6('Element gone right');
            setDuckPic1('Element gone right');
            setDuckPic2('Element gone right');

            }
            if(aktuelleSzene == 'diagnoseszene')
            {
            setDatenPapier('Element gone');
            setTestSpeaker('TestSpeaker_gone');  
            }
        }
    
    })

  return (
    <div className='WorldWrapper'>

        <div className={tischplatte}></div>
       

        <div className={DatenPapier}></div>
        <div className={Tape1}></div>
        <div className={Tape2}></div>
        <div className={Tape3}></div>
        <div className={Tape4}></div>

        <div className={duckPic1}>
            <div className={DuckPicFrame}><div className={KussiPic}></div></div>
        </div>
        <div className={duckPic2}>
            <div className={DuckPicFrame}><div className='SingPic'></div>
            </div>
            
            
        </div>
        <div className={Tape5}></div>
        <div className={Tape6}></div>

        <div className={TestSpeaker}>
            
        </div>

        <div className={DekoSmartphone}>
            <div className={DSMPFrame}>
                <div className={DSMPScreen}>
                    <div className='DSMPS_Time'>10:10</div>
                    <div className='DSMPS_google'>G</div>
                    <div className='DSMPS_Apps'>
                        <div className='DSMPSA1'></div>
                        <div className='DSMPSA2'></div>
                        <div className='DSMPSA3'></div>
                        <div className='DSMPSA4'></div>
                    </div>
                </div>
                <div className={DSMPHB}></div>
            </div>
            <div className={Rotate1}></div>
            <div className={Rotate2}></div>
        </div>
        
    </div>
  )
}

export default World