import React, { useEffect, useState } from 'react'
import '../styles/Target.css';

function TargetGroß(props) {

    var TargetText = props.TargetText;
    var TargetFont = props.TargetFont;
    var TargetType = props.TargetType;
    var theTheme = props.Theme;
    var TargetVisibility = props.Visibility;
    const [Font, setFont] = useState('0');
    const [Type, setType] = useState('0');
    const [WorldTheme, setWorldTheme] = useState('Default');
    const [TypeSelection, setTypeSelection] = useState('TargetType1');
    const [TargetFrame, setTargetFrame] = useState('TargetFrameSD');
    const [UpperTitel, setUpperTitel] = useState('UpperTitelDefault');
    const [LowerTitel, setLowerTitel] = useState('LowerTitelDefault');
    const [Tape, setTape] = useState('TapeDefault');
    const [WantedWindow, setWantedWindow] = useState('WantedWindowDefault');
    const [TargetWrapper, setTargetWrapper] = useState('TargetWrapperDefault');
    const [Rotator, setRotator] = useState('RotatorDefault');

    useEffect(()=> {
        if(theTheme == "SecretDucks"){
            setWorldTheme('SecretDucks');
            setTargetWrapper('TargetWrapperSD');
            setTargetFrame('TargetFrameSD')
            setUpperTitel('UpperTitelSD');
            setLowerTitel('LowerTitelSD');
            setTape('TapeSD');
            setWantedWindow('WantedWindowSD');
            setRotator('RotatorSD')
            
        }
        if(theTheme == "Default"){
            setWorldTheme('Default');
            setTargetWrapper('TargetWrapperDefault');
            setTargetFrame('TargetFrameDefault')
            setUpperTitel('UpperTitelDefault');
            setUpperTitel('LowerTitelDefault');
            setTape('TapeDefault');
            setWantedWindow('WantedWindowDefault');
        }
    })
    
    useEffect(() => {
        if(TargetType =="1"){setTypeSelection('TargetType1')}
        if(TargetType =="2"){setTypeSelection('TargetType2')}
        if(TargetType =="5"){setTypeSelection('TargetType5')}
        if(TargetType =="6"){setTypeSelection('TargetType6')}
        if(TargetType =="3"){
            setTypeSelection('TargetType3');
            if(TargetText=="img001")
            {
                setTypeSelection('TargetType3 Maus');
            }
            if(TargetText=="Nase")
            {
                setTypeSelection('TargetType3 Nase');
            }
            if(TargetText=="Boden")
            {
                setTypeSelection('TargetType3 Boden');
            }
            if(TargetText=="denken")
            {
                setTypeSelection('TargetType3 Denken');
            }
            if(TargetText=="finden")
            {
                setTypeSelection('TargetType3 Finden');
            }
            if(TargetText=="Entenerpel")
            {
                setTypeSelection('TargetType3 Entenerpel');
            }
            if(TargetText=="Feigenkerne")
            {
                setTypeSelection('TargetType3 Feigenkerne');
            }
            if(TargetText=="verbogen")
            {
                setTypeSelection('TargetType3 Verbogen');
            }
            if(TargetText=="gelikt")
            {
                setTypeSelection('TargetType3 Gelikt');
            }
            if(TargetText=="Besen")
            {
                setTypeSelection('TargetType3 Besen');
            }
            if(TargetText=="Farbe")
            {
                setTypeSelection('TargetType3 Farbe');
            }
            if(TargetText=="fegen")
            {
                setTypeSelection('TargetType3 fege');
            }
            if(TargetText=="baden")
            {
                setTypeSelection('TargetType3 badet');
            }
            if(TargetText=="Pudelbeine")
            {
                setTypeSelection('TargetType3 Pudelbeine');
            }
            if(TargetText=="Notenhefte")
            {
                setTypeSelection('TargetType3 Notenhefte');
            }
            if(TargetText=="gelogen")
            {
                setTypeSelection('TargetType3 gelogen');
            }
            if(TargetText=="downloaden")
            {
                setTypeSelection('TargetType3 downloaden');
            }
            if(TargetText=="Entenküken")
            {
                setTypeSelection('TargetType3 Entenküken');
            }
            if(TargetText=="gehoben")
            {
                setTypeSelection('TargetType3 gehoben');
            }
            if(TargetText=="Hose")
            {
                setTypeSelection('TargetType3 Hose');
            }
            if(TargetText=="Gabel")
            {
                setTypeSelection('TargetType3 Gabel');
            }
            if(TargetText=="tanzen")
            {
                setTypeSelection('TargetType3 tanzen');
            }
            if(TargetText=="reden")
            {
                setTypeSelection('TargetType3 reden');
            }
            if(TargetText=="Vortänzer")
            {
                setTypeSelection('TargetType3 Vortänzer');
            }
            if(TargetText=="Verkäufer")
            {
                setTypeSelection('TargetType3 Verkäufer');
            }
            if(TargetText=="verbunden")
            {
                setTypeSelection('TargetType3 verbunden');
            }
            if(TargetText=="ausgetrunken")
            {
                setTypeSelection('TargetType3 ausgetrunken');
            }
            if(TargetText=="Möbellager")
            {
                setTypeSelection('TargetType3 Möbellager');
            }
            if(TargetText=="Golfspielen")
            {
                setTypeSelection('TargetType3 Golfspielen');
            }
            if(TargetText=="Comicbuch")
            {
                setTypeSelection('TargetType3 Comicbuch');
            }
            if(TargetText=="Rindersteak")
            {
                setTypeSelection('TargetType3 Rindersteak');
            }
            if(TargetText=="gingen")
            {
                setTypeSelection('TargetType3 gingen');
            }
            if(TargetText=="trugen")
            {
                setTypeSelection('TargetType3 trugen');
            }
            if(TargetText=="Gänse")
            {
                setTypeSelection('TargetType3 Gänse');
            }
            if(TargetText=="Bäume")
            {
                setTypeSelection('TargetType3 Bäume');
            }
            if(TargetText=="Regenwolke")
            {
                setTypeSelection('TargetType3 Regenwolke');
            }
            if(TargetText=="Brotdose")
            {
                setTypeSelection('TargetType3 Brotdose');
            }
            if(TargetText=="Hasenhaus")
            {
                setTypeSelection('TargetType3 Hasenhaus');
            }
            if(TargetText=="Korb")
            {
                setTypeSelection('TargetType3 Korb');
            }
            if(TargetText=="Hemd")
            {
                setTypeSelection('TargetType3 Hemd');
            }
            if(TargetText=="Brett")
            {
                setTypeSelection('TargetType3 Brett');
            }
            if(TargetText=="Nuss")
            {
                setTypeSelection('TargetType3 Nuss');
            }

        }

    })


    

  return (
      <div className={TargetWrapper}>
            <div className={TargetFrame}>
                <div className={UpperTitel}>! ! ! </div>
                <div className={Rotator}></div>
                <div className={WantedWindow}>
                    <div className='TargetFeld'>
                        <div className={TypeSelection}>{TargetText}</div>
                    </div>

                </div>
                <div className={LowerTitel}>! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! ! !</div>
            </div>
            <div className={Tape}></div>
      </div>
     
         
    
  )
}

export default TargetGroß