import React, { useEffect } from 'react'
import '../styles/Button.css'
import { useState } from 'react';

function Button(props) {

    var Type = props.Type;
    var theTheme = props.Theme;
    const [ButtonType, setButtonType] = useState('Button Inaktiv');
    const [ButtonIcon, setButtonIcon] = useState('Icon Inaktiv');
    const [WorldTheme, setWorldTheme] = useState('Default');

    useEffect(()=> {
      if(theTheme == "SecretDucks"){
          setWorldTheme('SecretDucks');          
      }
      if(theTheme == "Default"){
          setWorldTheme('Default');
      }
  })

    useEffect(() => {
        if(Type == 'Same'){if(theTheme=="Default"){setButtonType('Button SameDefault'); setButtonIcon('Icon Same')}
                          if(theTheme=="SecretDucks"){setButtonType('Button SameSD'); setButtonIcon('Icon Same')}}
        if(Type == 'Different'){if(theTheme=="Default"){setButtonType('Button DifferentDefault'); setButtonIcon('Icon Different')}
        if(theTheme=="SecretDucks"){setButtonType('Button DifferentSD'); setButtonIcon('Icon Different')}}
        if(Type == 'Inaktiv')
        {
        if(theTheme=="SecretDucks"){setButtonType('Button InaktivSD'); setButtonIcon('Icon Inaktiv')}
        if(theTheme=="Default"){setButtonType('Button InaktivDefault'); setButtonIcon('Icon Inaktiv')}
    }})

  return (
    <div className='ButtonFrame'>
        <div className={ButtonType}>
            <div className={ButtonIcon}></div>
        </div>
    </div>
  )
}

export default Button