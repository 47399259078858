import React, { useEffect, useState } from 'react'
import '../styles/Stimulus.css';

function Stimulus(props) {

    var StimulusText = props.StimulusText;
    var StimType = props.StimulusType;
    var theTheme = props.Theme;
    const [StimulusType, setStimulusType] = useState('StimulusTextType1');
    const [WorldTheme, setWorldTheme] = useState('Default');
    const [StimWrapper, setStimulusWrapper] = useState('StimWrapperDefault');

    useEffect(()=> {
      if(theTheme == "SecretDucks"){
          setWorldTheme('SecretDucks'); 
          setStimulusWrapper('StimWrapperSD')         
      }
      if(theTheme == "Default"){
          setWorldTheme('Default');
          setStimulusWrapper('StimWrapperDefault')  
      }
  })

    useEffect(() => {
      if (StimType == "1"){setStimulusType('StimulusTextType1')}
      if (StimType == "2"){setStimulusType('StimulusTextType2')}
      if (StimType == "3"){setStimulusType('StimulusTextType1')}
      if (StimType == "4"){setStimulusType('StimulusTextType4')}
      if (StimType == "5"){setStimulusType('StimulusTextType5')}
      if (StimType == "6"){setStimulusType('StimulusTextType6')}
    })


  return (
    <div className={StimWrapper}>
        <div className={StimulusType}>{StimulusText}</div>
    </div>
  )
}

export default Stimulus