import React, { useEffect } from 'react'
import '../styles/TargetKlein.css'
import { useState } from 'react';

function TargetKlein(props) {

    var TargetText = props.TargetText;
    var Type = props.TargetType;
    var theTheme = props.Theme;
    const [TargetType, setTargetType] = useState("");
    const [WorldTheme, setWorldTheme] = useState('Default');
    const [Glas, setGlas] = useState('LupeKleinGlasDefault');
    const [Stiel, setStiel] = useState('LupeKleinStielDefault')

    useEffect(()=> {
      if(theTheme == "SecretDucks"){
          setWorldTheme('SecretDucks'); 
          setGlas('LupeKleinGlasSD');  
          setStiel('LupeKleinStielSD');       
      }
      if(theTheme == "Default"){
          setWorldTheme('Default');
          setGlas('LupeKleinGlasDefault');  
          setStiel('LupeKleinStielDefault'); 
      }
  })

    useEffect(() => {
      if(Type=="1"){setTargetType("TargetTypeKlein1");}
      if(Type=="2"){setTargetType("TargetTypeKlein2");}
      if(Type=="5"){setTargetType("TargetTypeKlein5");}
      if(Type=="6"){setTargetType("TargetTypeKlein6");}
      if(Type =="3"){
        setTargetType('TargetType3');
        if(TargetText=="img001")
        {
          setTargetType('TargetTypeKlein3 Maus');
        }
        if(TargetText=="Nase")
        {
          setTargetType('TargetTypeKlein3 Nase');
        }
        if(TargetText=="Boden")
        {
          setTargetType('TargetTypeKlein3 Boden');
        }
        if(TargetText=="denken")
        {
          setTargetType('TargetTypeKlein3 Denken');
        }
        if(TargetText=="finden")
        {
          setTargetType('TargetTypeKlein3 Finden');
        }
        if(TargetText=="Entenerpel")
        {
          setTargetType('TargetTypeKlein3 Entenerpel');
        }
        if(TargetText=="Feigenkerne")
        {
          setTargetType('TargetTypeKlein3 Feigenkerne');
        }
        if(TargetText=="verbogen")
        {
          setTargetType('TargetTypeKlein3 Verbogen');
        }
        if(TargetText=="gelikt")
        {
          setTargetType('TargetTypeKlein3 Gelikt');
        }
        if(TargetText=="Besen")
        {
          setTargetType('TargetTypeKlein3 Besen');
        }
        if(TargetText=="Farbe")
        {
          setTargetType('TargetTypeKlein3 Farbe');
        }
        if(TargetText=="fegen")
        {
          setTargetType('TargetTypeKlein3 fege');
        }
        if(TargetText=="baden")
        {
          setTargetType('TargetTypeKlein3 badet');
        }
        if(TargetText=="Pudelbeine")
        {
          setTargetType('TargetTypeKlein3 Pudelbeine');
        }
        if(TargetText=="Notenhefte")
        {
          setTargetType('TargetTypeKlein3 Notenhefte');
        }
        if(TargetText=="gelogen")
        {
          setTargetType('TargetTypeKlein3 gelogen');
        }
        if(TargetText=="downloaden")
        {
          setTargetType('TargetTypeKlein3 downloaden');
        }
        if(TargetText=="Entenküken")
        {
          setTargetType('TargetTypeKlein3 Entenküken');
        }
        if(TargetText=="gehoben")
        {
          setTargetType('TargetTypeKlein3 gehoben');
        }
        if(TargetText=="Hose")
        {
          setTargetType('TargetTypeKlein3 Hose');
        }
        if(TargetText=="Gabel")
        {
          setTargetType('TargetTypeKlein3 Gabel');
        }
        if(TargetText=="tanzen")
        {
          setTargetType('TargetTypeKlein3 tanzen');
        }
        if(TargetText=="reden")
        {
          setTargetType('TargetTypeKlein3 reden');
        }
        if(TargetText=="Vortänzer")
        {
          setTargetType('TargetTypeKlein3 Vortänzer');
        }
        if(TargetText=="Verkäufer")
        {
          setTargetType('TargetTypeKlein3 Verkäufer');
        }
        if(TargetText=="verbunden")
        {
          setTargetType('TargetTypeKlein3 verbunden');
        }
        if(TargetText=="ausgetrunken")
        {
          setTargetType('TargetTypeKlein3 ausgetrunken');
        }
        if(TargetText=="Möbellager")
        {
          setTargetType('TargetTypeKlein3 Möbellager');
        }
        if(TargetText=="Golfspielen")
        {
          setTargetType('TargetTypeKlein3 Golfspielen');
        }
        if(TargetText=="Comicbuch")
        {
          setTargetType('TargetTypeKlein3 Comicbuch');
        }
        if(TargetText=="Rindersteak")
        {
          setTargetType('TargetTypeKlein3 Rindersteak');
        }
        if(TargetText=="gingen")
        {
          setTargetType('TargetTypeKlein3 gingen');
        }
        if(TargetText=="trugen")
        {
          setTargetType('TargetTypeKlein3 trugen');
        }
        if(TargetText=="Gänse")
        {
          setTargetType('TargetTypeKlein3 Gänse');
        }
        if(TargetText=="Bäume")
        {
          setTargetType('TargetTypeKlein3 Bäume');
        }
        if(TargetText=="Regenwolke")
        {
          setTargetType('TargetTypeKlein3 Regenwolke');
        }
        if(TargetText=="Brotdose")
        {
          setTargetType('TargetTypeKlein3 Brotdose');
        }
        if(TargetText=="Hasenhaus")
        {
          setTargetType('TargetTypeKlein3 Hasenhaus');
        }
        if(TargetText=="Korb")
        {
          setTargetType('TargetTypeKlein3 Korb');
        }
        if(TargetText=="Hemd")
        {
          setTargetType('TargetTypeKlein3 Hemd');
        }
        if(TargetText=="Brett")
        {
          setTargetType('TargetTypeKlein3 Brett');
        }
        if(TargetText=="Nuss")
        {
          setTargetType('TargetTypeKlein3 Nuss');
        }


    }
    })


  return (
    <div className='TargetKleinWrapper'>
        <div className={Glas}>
            <div className={TargetType}>{TargetText}</div>
        </div>
        <div className={Stiel}></div>
    </div>
  )
}

export default TargetKlein